import React, { createContext, useEffect, useState } from "react";
import { AuthOptions, WebAuth } from "auth0-js";
import { useHistory, useLocation } from "react-router-dom";
import { message } from "antd";
import { useAppDispatch } from "../../app/store";
import {
  setCount,
  setEmail,
  setLoginMethod,
} from "../../features/redemptionSlice";
import { setLoginOptions } from "../../features/auth/authSlice";

interface AuthContextProps {
  isAuthenticated: boolean;
  loading: boolean;
  accessToken?: string;
  sub?: string;
  login: (username: string, password: string) => Promise<any>;
  signup: (username: string, password: string) => Promise<any>;
  logout: () => void;
  checkPassword: (username: string, password: string) => Promise<any>;
  changePassword: (email: string) => Promise<any>;
}

const initialAuthState: AuthContextProps = {
  isAuthenticated: false,
  loading: false,
  login: () => new Promise((resolve) => resolve(null)),
  signup: () => new Promise((resolve) => resolve(null)),
  logout: () => {},
  checkPassword: () => new Promise((resolve) => resolve(null)),
  changePassword: () => new Promise((resolve) => resolve(null)),
};

export const AuthContext = createContext<AuthContextProps>(initialAuthState);

/** TODO: refactor useState to useReducer! */
export function AuthProvider(props: React.PropsWithChildren<AuthOptions>) {
  const { children, ...options } = props;
  const webAuth = new WebAuth(options);
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [accessToken, setAccessToken] = useState<string>();
  const [sub, setSub] = useState<string>();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const { search } = useLocation();
  const dispatch = useAppDispatch();

  /* add auth0 callback listener to parse & store auth0 response. */
  useEffect(() => {
    if (window.location.hash) {
      dispatch(setCount(2));
      webAuth.parseHash((error, result) => {
        history.replace({
          pathname: "/",
          search: search,
        });
        if (error) {
          return message.error(error.error);
        }
        if (result) {
          // setAuthResult(result);
          setAccessToken(result.accessToken);
          webAuth.client.userInfo(result.accessToken ?? "", (err, response) => {
            if (err) {
              return Promise.reject(new Error(err.description));
            }
            setSub(response.sub);
          });
          setIsAuthenticated(true);
        }
      });
    }
  }, []);

  function checkPassword(email: string, password: string) {
    return new Promise((resolve, reject) => {
      setLoading(true);
      webAuth.client.login(
        {
          username: email,
          password,
          realm: "Username-Password-Authentication",
        },
        (err, result) => {
          setLoading(false);
          if (err) {
            reject(new Error(err.description));
          } else if (!result) {
            reject(new Error("login with no authResult!"));
          } else {
            setAccessToken(result["accessToken"]);
            resolve(result["accessToken"]);
          }
        }
      );
    }).then((token) => {
      webAuth.client.userInfo(token as string, (err, response) => {
        if (err) {
          return Promise.reject(new Error(err.description));
        }
        setSub(response.sub);
        return Promise.resolve(response.sub);
      });
    });
  }

  function login(email: string, password: string) {
    // TODO: webAuth.logout will make a GET /login call to auth0 and then get a 302 response.
    return new Promise((resolve, reject) => {
      setLoading(true);
      webAuth.login({ email, password }, (err, result) => {
        setLoading(false);
        if (err) {
          reject(new Error(err.error_description));
        } else if (!result) {
          reject(new Error("login with no authResult!"));
        }
        resolve(result);
      });
    });
  }

  function signup(email: string, password: string) {
    return new Promise((resolve, reject) => {
      setLoading(true);
      webAuth.signup(
        { email, password, connection: "Username-Password-Authentication" },
        (error, result) => {
          setLoading(false);
          if (error) {
            reject(new Error(error.description));
          } else {
            dispatch(setLoginMethod("signup"));
            setSub(result.Id);
            setLoginOptions({ username: email, password });
            setEmail(email);
            setLoading(true);
            dispatch(setCount(1));
            resolve(result);
            webAuth.login(
              { email, password, realm: "Username-Password-Authentication" },
              (err) => {
                console.log("Login error:", err);
              }
            );
          }
        }
      );
    });
  }

  function logout() {
    // TODO: webAuth.logout will make a GET /logout call to auth0 and then get a 302 response.
    return webAuth.logout({ returnTo: window.location.origin });
  }

  function changePassword(email: string) {
    return new Promise((resolve, reject) => {
      setLoading(true);
      webAuth.changePassword(
        { connection: "Username-Password-Authentication", email },
        (error, result) => {
          if (error) {
            reject(new Error(error.description));
          } else {
            resolve(result);
          }
          setLoading(false);
        }
      );
    });
  }

  return (
    <AuthContext.Provider
      value={{
        ...initialAuthState,
        loading,
        isAuthenticated,
        accessToken,
        sub,
        login,
        signup,
        logout,
        checkPassword,
        changePassword,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
