import React from "react";
import { Provider } from "react-redux";
import { render } from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";
import { Spin } from "antd";
import "antd/dist/antd.less";
import { store } from "./app/store";
import App from "./component/App";
import { AuthProvider } from "./component/AuthContext";
import "./styles/index.scss";

let persistor = persistStore(store);

render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={<Spin />} persistor={persistor}>
        <BrowserRouter>
          <AuthProvider
            domain={process.env.REACT_APP_AUTH0_DOMAIN}
            clientID={process.env.REACT_APP_CLIENT_ID}
            audience={process.env.REACT_APP_AUTH0_AUDIENCE}
            responseType={"token id_token"}
            scope={"openid profile email"}
            redirectUri={window.location.origin}
          >
            <App />
          </AuthProvider>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);
