import React, { useEffect, useState } from "react";
import { Button, Form, Select, Space } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { Course, DropDown } from "assets/icons";
import { RootState, useAppDispatch } from "app/store";
import { useSelector } from "react-redux";
import _, { groupBy, isEmpty } from "lodash";
import {
  decrement,
  fetchRedeemableProducts,
  increment,
  setSelectedProduct,
} from "features/redemptionSlice";
import { logout } from "features/auth/authSlice";

import { RedeemableProductModel } from "../../types/redemption";
import styles from "./RedeemableProduct.module.scss";
import { useAuth } from "../../hooks";

function RedeemableProduct({ code }: { code?: string }) {
  const { accessToken } = useAuth();
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const { redeemableProducts } = useSelector(
    (state: RootState) => state.redemption
  );

  useEffect(() => {
    !_.isEmpty(code) &&
      !_.isEmpty(accessToken) &&
      dispatch(fetchRedeemableProducts({ voucherCode: code!, accessToken }));
  }, [dispatch, code, accessToken]);

  const languageOptions = Object.keys(
    groupBy(redeemableProducts, "languageName")
  );

  const [productOptions, setProductOptions] = useState<
    RedeemableProductModel[]
  >(languageOptions.length === 1 ? redeemableProducts : []);

  const handleLanguageChange = (value: string) => {
    const options = redeemableProducts.filter(
      (product) => product.languageName === value
    );
    setProductOptions(options);
    form.resetFields(["productId"]);
  };

  const goBack = () => {
    dispatch(logout());
    dispatch(decrement());
  };

  const goNext = () => {
    form.submit();
    const { productId } = form.getFieldsValue();
    const selectedProduct = redeemableProducts.find(
      (product) => product.productId === productId
    );
    if (selectedProduct) {
      dispatch(setSelectedProduct(selectedProduct));
      dispatch(increment());
    }
  };

  return (
    <Space
      direction={"vertical"}
      align={"center"}
      size={12}
      className={styles.form}
    >
      <Course />
      <h3>Choose your course here</h3>
      <div className={styles.tip}>
        Premium versions add enhanced features to help you review course content
        and get an even deeper understanding of the language.
      </div>
      <Form form={form} validateTrigger={["onBlur"]}>
        <Form.Item
          label="Language"
          name="language"
          initialValue={
            languageOptions.length === 1 ? languageOptions[0] : undefined
          }
          rules={[{ required: true, message: "'Language' is required" }]}
        >
          <Select
            showSearch
            listHeight={160}
            optionFilterProp={"label"}
            getPopupContainer={(trigger) => trigger.parentNode}
            suffixIcon={
              isEmpty(languageOptions) ? <LoadingOutlined /> : <DropDown />
            }
            notFoundContent="Loading..."
            placeholder={"Please choose language"}
            onChange={handleLanguageChange}
            options={languageOptions.map((language) => ({
              label: language,
              value: language,
            }))}
          />
        </Form.Item>
        <Form.Item
          label="Course level"
          name="productId"
          initialValue={
            productOptions.length === 1
              ? productOptions[0].productId
              : undefined
          }
          rules={[{ required: true, message: "'Course level' is required" }]}
        >
          <Select
            showSearch
            listHeight={160}
            optionFilterProp={"label"}
            getPopupContainer={(trigger) => trigger.parentNode}
            suffixIcon={<DropDown />}
            placeholder={"Please choose course level"}
            options={productOptions.map((product) => ({
              label: product.productName,
              value: product.productId,
            }))}
          />
        </Form.Item>
      </Form>
      <Space direction={"horizontal"} style={{ marginTop: "43px" }}>
        <Button shape="round" onClick={goBack}>
          Back
        </Button>
        <Button type="primary" shape="round" onClick={goNext}>
          Continue
        </Button>
      </Space>
    </Space>
  );
}

export default RedeemableProduct;
