import { combineReducers } from "@reduxjs/toolkit";
import redemptionReducer from "features/redemptionSlice";
import authReducer from "features/auth/authSlice";

const rootReducer = combineReducers({
  redemption: redemptionReducer,
  auth: authReducer,
});

export default rootReducer;
