import React from "react";
import { useSelector } from "react-redux";
import { Button, Form, message, Space } from "antd";
import { unwrapResult } from "@reduxjs/toolkit";
import { increment, decrement, redeem } from "features/redemptionSlice";
import { Confirmation } from "assets/icons";
import { useAuth } from "hooks";
import { RootState, useAppDispatch } from "../../app/store";
import { RedeemableProductModel } from "../../types/redemption";
import styles from "./RedeemConfirmation.module.scss";

interface Props {
  code?: string;
  selectedProduct?: RedeemableProductModel;
}

function RedeemConfirmation({ code, selectedProduct }: Props) {
  const { accessToken, sub } = useAuth();
  const dispatch = useAppDispatch();
  const { email } = useSelector((state: RootState) => state.redemption);
  const { isLoading } = useSelector((state: RootState) => state.redemption);

  const goNext = () => {
    if (email != null && sub != null) {
      dispatch(
        redeem({
          email,
          voucherCode: code!,
          productId: selectedProduct?.productId!,
          auth0Id: sub,
          accessToken,
        })
      )
        .then(unwrapResult)
        .then(() => dispatch(increment()))
        .catch((err) => message.error(err.message));
    }
  };

  return (
    <Space
      direction={"vertical"}
      align={"center"}
      size={12}
      className={styles.redeemConfirmation}
    >
      <Confirmation />
      <h3>Voucher redemption confirmation</h3>
      <Form className={styles.form}>
        <Form.Item label="User email">
          <span>{email}</span>
        </Form.Item>
        <Form.Item label="Voucher code">
          <span>{code}</span>
        </Form.Item>
        <Form.Item label="Redeemed course">
          <span>{selectedProduct?.productName}</span>
        </Form.Item>
      </Form>
      <Space direction={"horizontal"} style={{ marginTop: "20px" }}>
        <Button shape="round" onClick={() => dispatch(decrement())}>
          Back
        </Button>
        <Button
          type="primary"
          shape="round"
          onClick={goNext}
          loading={isLoading}
        >
          Continue
        </Button>
      </Space>
    </Space>
  );
}

export default RedeemConfirmation;
