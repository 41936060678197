import {
  createAsyncThunk,
  createSlice,
  isFulfilled,
  isPending,
  isRejected,
  PayloadAction,
} from "@reduxjs/toolkit";
import { RedeemableProductModel } from "../types/redemption";
import * as redemptionAPI from "../services/redeemption";

interface IState {
  step: number;
  code?: string;
  isLoading: boolean;
  email?: string;
  selectedProduct?: RedeemableProductModel;
  redeemableProducts: RedeemableProductModel[];
  remainingRedeemableAmount?: number;
  loginMethod?: string;
}

const initialState: IState = {
  step: 0,
  isLoading: false,
  redeemableProducts: [],
};

export const fetchRedeemableProducts = createAsyncThunk(
  "voucher/redeemableProducts",
  redemptionAPI.fetchRedeemableProducts
);

export const isRedeemable = createAsyncThunk(
  "voucher/isRedeemable",
  redemptionAPI.isRedeemable
);

export const redeem = createAsyncThunk("voucher/redeem", redemptionAPI.redeem);

export const redemptionSlice = createSlice({
  name: "redemption",
  initialState: initialState,
  reducers: {
    increment: (state) => {
      state.step += 1;
    },
    decrement: (state) => {
      state.step -= 1;
    },
    setCount: (state, { payload }: PayloadAction<number>) => {
      state.step = payload;
    },
    setCode: (state, { payload }: PayloadAction<string>) => {
      state.code = payload;
    },
    setEmail: (state, { payload }: PayloadAction<string>) => {
      state.email = payload;
    },
    setSelectedProduct: (
      state,
      { payload }: PayloadAction<RedeemableProductModel>
    ) => {
      state.selectedProduct = payload;
    },
    setLoginMethod: (state, { payload }: PayloadAction<string>) => {
      state.loginMethod = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchRedeemableProducts.fulfilled, (state, { payload }) => {
      state.redeemableProducts = payload;
    });
    builder.addCase(redeem.fulfilled, (state, { payload }) => {
      state.remainingRedeemableAmount = payload.remainingRedeemableAmount;
    });
    builder.addMatcher(isPending, (state) => {
      state.isLoading = true;
    });
    builder.addMatcher(isFulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addMatcher(isRejected, (state) => {
      state.isLoading = false;
    });
  },
});

export const {
  increment,
  decrement,
  setCount,
  setCode,
  setEmail,
  setSelectedProduct,
  setLoginMethod,
} = redemptionSlice.actions;

export default redemptionSlice.reducer;
