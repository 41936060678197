import React, { PropsWithChildren } from "react";
import { useLocation } from "react-router-dom";
import * as qs from "query-string";
import { Button, Col, Form, Input, Row } from "antd";
import { FormProps } from "antd/es/form";
import { useAuth } from "hooks";

type Values = {
  email: string;
  password: string;
};

interface Props extends PropsWithChildren<Pick<FormProps<Values>, "onFinish">> {
  submitText: string;
  signup?: boolean;
  passwordless?: boolean;
  onBack?: () => void;
}

function LoginForm({
  submitText,
  signup = false,
  passwordless = false,
  onFinish,
  onBack,
  children,
}: Props) {
  const [form] = Form.useForm();
  const { loading } = useAuth();
  const { search } = useLocation();
  const query = qs.parse(search, {
    arrayFormat: "comma",
    parseBooleans: true,
    parseNumbers: true,
  });

  return (
    <Form
      layout={"vertical"}
      validateTrigger={"onBlur"}
      form={form}
      onFinish={onFinish}
    >
      <Form.Item
        label="Email address"
        name={"email"}
        initialValue={query?.email}
        rules={[
          { required: true, type: "email" },
          { type: "string", max: 100 },
        ]}
      >
        <Input allowClear />
      </Form.Item>
      {!passwordless && (
        <Form.Item
          label="Password"
          name={"password"}
          rules={
            signup
              ? [
                  {
                    required: true,
                    pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d@$!%*#?&]{8,}$/,
                  },
                ]
              : [
                  {
                    required: true,
                  },
                ]
          }
          help={
            signup
              ? "Min. 8 characters with uppercase, lowercase, and numbers"
              : undefined
          }
        >
          <Input.Password />
        </Form.Item>
      )}

      {children}

      <Row
        justify={"center"}
        gutter={12}
        style={{ marginTop: "16px", marginBottom: "16px" }}
      >
        <Col span={10}>
          <Button type="default" shape="round" block onClick={onBack}>
            Back
          </Button>
        </Col>
        <Col span={10}>
          <Button
            type="primary"
            shape="round"
            block
            htmlType={"submit"}
            loading={loading}
          >
            {submitText}
          </Button>
        </Col>
      </Row>
    </Form>
  );
}

export default LoginForm;
