import React, { useState, ChangeEvent } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import * as qs from "query-string";
import { Button, Checkbox, Input, message, Space } from "antd";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import { Confirmation } from "assets/icons";
import { TERMS } from "constants/common";
import { unwrapResult } from "@reduxjs/toolkit";
import { increment, setCode, isRedeemable } from "features/redemptionSlice";
import { RootState, useAppDispatch } from "app/store";
import styles from "./VoucherRedemption.module.scss";

function VoucherRedemption() {
  const [agreeTerms, setAgreeTerms] = useState(false);
  const { search } = useLocation();
  const query = qs.parse(search, {
    arrayFormat: "comma",
    parseBooleans: true,
    parseNumbers: true,
  });
  const [redemptionCode, setRedemptionCode] = useState(query?.code as string);
  const { isLoading } = useSelector((state: RootState) => state.redemption);

  const dispatch = useAppDispatch();

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setRedemptionCode(event.target.value.trim());
  };

  const handleCheckbox = (event: CheckboxChangeEvent) => {
    setAgreeTerms(event.target.checked);
  };

  const goNext = () => {
    if (redemptionCode != null) {
      dispatch(setCode(redemptionCode));
      dispatch(increment());
    }
  };

  const handleClickContinue = () => {
    dispatch(isRedeemable(redemptionCode))
      .then(unwrapResult)
      .then(() => goNext())
      .catch((err) => message.error(err.message));
  };

  return (
    <Space
      direction={"vertical"}
      align={"center"}
      className={styles.voucherRedemption}
    >
      <Confirmation />
      <h3>Enter your redemption code</h3>
      <Input
        allowClear
        autoFocus
        value={redemptionCode}
        placeholder="Enter Redemption Code"
        onChange={handleInputChange}
        className={styles.codeInput}
      />
      <Space direction={"vertical"} align={"start"}>
        <div className={styles.terms}>{TERMS}</div>
        <Checkbox checked={agreeTerms} onChange={handleCheckbox}>
          I have read and agree to the terms above
        </Checkbox>
      </Space>
      <Button
        disabled={!(redemptionCode != null && agreeTerms)}
        type="primary"
        shape="round"
        className={styles.continueBtn}
        loading={isLoading}
        onClick={handleClickContinue}
      >
        Continue
      </Button>
    </Space>
  );
}

export default VoucherRedemption;
