import axios from "axios";
import { stringify } from "qs";
import { omitBy, isEmpty } from "lodash";

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  // query params issue: https://github.com/axios/axios/issues/1727
  paramsSerializer: (params) => {
    const paramsTemp = omitBy(params, (value: any) => {
      if (typeof value === "string" || value instanceof Array) {
        return isEmpty(value);
      }
    });
    return stringify(paramsTemp, { arrayFormat: "comma" });
  },
});

instance.interceptors.request.use(async (config) => {
  return config;
});

instance.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (err) => {
    let message;
    if (err.response != null) {
      const { status, data } = err.response;
      message = data?.detail || data?.violations?.[0]?.message || data?.title;
      if (message == null) {
        switch (status) {
          case 400:
            message = "Bad Request";
            break;
          case 401:
            message = "Sorry, you don't have authorization.";
            break;
          case 403:
            message = "Sorry, you don't have access to this page.";
            break;
          case 404:
            message = "Sorry, can’t find the specific resource.";
            break;
          case 405:
            message = "Method is incorrect";
            break;
          case 500:
            message = "Sorry, the server is reporting an error.";
            break;
          default:
            message = "Sorry, there are some errors.";
        }
      }
    }
    return Promise.reject(message);
  }
);

export default instance;
