import React from "react";
import { message } from "antd";
import { useAppDispatch } from "app/store";
import ViewContainer from "component/ViewContainer";
import { useAuth } from "hooks";
import { Lock } from "assets/icons";
import { setView } from "../authSlice";
import LoginForm from "../LoginForm";

function ResetPasswordView() {
  const dispatch = useAppDispatch();
  const { changePassword } = useAuth();

  return (
    <ViewContainer
      icon={<Lock />}
      title={"Reset your password"}
      subtitle={
        "Please enter your email address. We will send you an email to reset your password."
      }
    >
      <LoginForm
        submitText={"Continue"}
        passwordless={true}
        onFinish={({ email }) => {
          changePassword(email).then(() => {
            dispatch(setView("LOGIN"));
            return message.success(
              "We've just sent you an email to reset your password."
            );
          });
        }}
        onBack={() => dispatch(setView("LOGIN"))}
      />
    </ViewContainer>
  );
}

export default ResetPasswordView;
