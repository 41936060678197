import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "app/store";
import LoginView from "../LoginView";
import SignupView from "../SignupView";
import ResetPasswordView from "../ResetPasswordView";
import { setView } from "../authSlice";

function AuthView() {
  const { view } = useSelector((state: RootState) => state.auth);
  const dispatch = useAppDispatch();

  useEffect(() => {
    return function cleanup() {
      dispatch(setView("SIGNUP"));
    };
  }, [dispatch]);

  let content;
  switch (view) {
    case "LOGIN":
      content = <LoginView />;
      break;
    case "RESET":
      content = <ResetPasswordView />;
      break;
    case "SIGNUP":
      content = <SignupView />;
      break;
    default:
      content = <LoginView />;
  }

  return <>{content}</>;
}

export default AuthView;
