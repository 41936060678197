import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LoginOptions } from "auth0-js";

interface IState extends Partial<LoginOptions> {
  isAuthenticated: boolean;
  view: "LOGIN" | "SIGNUP" | "RESET";
}

const initialState: IState = {
  isAuthenticated: false,
  view: "SIGNUP",
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setView: (state, { payload }: PayloadAction<IState["view"]>) => {
      state.view = payload;
    },
    setLoginOptions: (state, { payload }: PayloadAction<LoginOptions>) => {
      state.username = payload.username;
      state.password = payload.password;
    },
    logout: (state) => {
      delete state.username;
      delete state.password;
    },
  },
});

export const { setView, setLoginOptions, logout } = authSlice.actions;

export default authSlice.reducer;
