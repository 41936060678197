import React, { ComponentProps } from "react";
import { Button, message, Row, Typography } from "antd";
import { Signup } from "assets/icons";
import { useAppDispatch } from "app/store";
import { decrement, setEmail } from "features/redemptionSlice";
import { setLoginOptions, setView } from "features/auth/authSlice";
import { useAuth } from "hooks";
import ViewContainer from "component/ViewContainer";
import LoginForm from "../LoginForm";

function SignupView() {
  const dispatch = useAppDispatch();
  const { signup } = useAuth();

  const handleSubmit: ComponentProps<typeof LoginForm>["onFinish"] = ({
    email,
    password,
  }) => {
    signup(email, password)
      .then(() => {
        dispatch(setLoginOptions({ username: email, password }));
        dispatch(setEmail(email));
      })
      .catch((err: Error) => {
        return message.error(err.message);
      });
  };

  return (
    <ViewContainer
      icon={<Signup />}
      title={"Create a Pimsleur account"}
      subtitle={
        "If this is your first voucher redemption,  please create an account"
      }
    >
      <LoginForm
        submitText={"Create account"}
        signup
        onBack={() => dispatch(decrement())}
        onFinish={handleSubmit}
      />

      <Row justify="center" align="middle">
        <Typography.Title level={5}>
          Already have an account?
          <Button
            type="link"
            onClick={() => dispatch(setView("LOGIN"))}
            style={{ fontSize: "16px", fontWeight: 600 }}
          >
            <u>Sign in</u>
          </Button>
        </Typography.Title>
      </Row>
    </ViewContainer>
  );
}

export default SignupView;
