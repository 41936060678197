import React, { MouseEventHandler } from "react";
import { useSelector } from "react-redux";
import { Button, Space } from "antd";
import { AppStore, Confirmation, GooglePlay } from "assets/icons";
import { setCount } from "features/redemptionSlice";
import { useAuth } from "hooks";
import { RootState, useAppDispatch } from "app/store";
import styles from "./RedeemedSuccessful.module.scss";

interface Props {
  remainingRedeemableAmount?: number;
}

function RedeemedSuccessful({ remainingRedeemableAmount }: Props) {
  const dispatch = useAppDispatch();
  const { username, password } = useSelector((state: RootState) => state.auth);
  const { login } = useAuth();

  const goToLearnApp: MouseEventHandler = () => {
    if (username != null && password != null) {
      return login(username, password);
    }
  };

  return (
    <Space
      direction={"vertical"}
      align={"center"}
      size={12}
      className={styles.redeemedSuccessful}
    >
      <Confirmation />
      <h3>Your course has been redeemed!</h3>
      <Space
        direction={"vertical"}
        align={"center"}
        className={styles.text}
        size={0}
      >
        <div>You have redeemed your course successfully.</div>
        <div>
          {"Start your learning journey with "}
          <a
            href={process.env.REACT_APP_PIMSLEUR_APP_DOMAIN}
            target="_blank"
            rel="noreferrer"
            onClick={goToLearnApp}
          >
            Pimsleur Learning Website
          </a>
        </div>
        <div>or get Pimsleur Mobile App</div>
        <Space
          direction={"horizontal"}
          style={{ marginTop: "22px", marginBottom: "0" }}
        >
          <a
            href="https://itunes.apple.com/us/app/pimsleur-language-learning/id1405735469"
            target="_blank"
            rel="noreferrer"
          >
            <AppStore />
          </a>
          <a
            href="https://play.google.com/store/apps/details?id=com.simonandschuster.pimsleur.unified.android"
            target="_blank"
            rel="noreferrer"
          >
            <GooglePlay />
          </a>
        </Space>
      </Space>
      <Space direction={"horizontal"}>
        {!!remainingRedeemableAmount && (
          <Button shape="round" onClick={() => dispatch(setCount(2))}>
            Redeem another course
          </Button>
        )}
        <Button
          type="primary"
          shape="round"
          onClick={() => dispatch(setCount(0))}
        >
          Redeem another voucher
        </Button>
      </Space>
    </Space>
  );
}

export default RedeemedSuccessful;
