export const TERMS =
  "By checking this box, you confirm (i) that you are at least 16 years of age," +
  "(ii) that you are using the Services in connection with a Pimsleur product " +
  "provided to you (or to a minor child for which you have legal custody, and are " +
  "thus agreeing on such minor’s behalf), either directly or through a redemption " +
  "code, by another person or entity in connection with an employment, sponsorship, " +
  "educational or other similar relationship (a “Sponsor”); and (ii) that we may " +
  "share with your Sponsor personal information provided by you or your usage information," +
  " which may allow the Sponsor to track your (or your child’s) progress and use of the" +
  " Pimsleur courses and Services. If you have questions regarding the information we share" +
  " with your Sponsor or your Sponsor’s use of this information, please contact your Sponsor.";
