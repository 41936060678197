import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Layout, Space, Steps } from "antd";
import VoucherRedemption from "features/VoucherRedemption";
import RedeemableProduct from "features/RedeemableProduct";
import RedeemConfirmation from "features/RedeemConfirmation";
import RedeemedSuccessful from "features/RedeemedSuccessful";
import { setCount } from "features/redemptionSlice";
import { useAuth } from "hooks";
import { RootState, useAppDispatch } from "../app/store";
import { Logo } from "../assets/icons";
import Footer from "./Footer";
import AuthView from "../features/auth/AuthView";
import styles from "./App.module.scss";

function App() {
  const {
    step,
    code,
    selectedProduct,
    remainingRedeemableAmount,
    loginMethod,
  } = useSelector((state: RootState) => state.redemption);
  const { isAuthenticated } = useAuth();
  const dispatch = useAppDispatch();

  useEffect(() => {
    const newCount = isAuthenticated ? (loginMethod === "signup" ? 2 : 4) : 0;
    dispatch(setCount(newCount));
  }, [dispatch, isAuthenticated, loginMethod]);

  let content;

  switch (step) {
    case 0:
      content = <VoucherRedemption />;
      break;
    case 1:
      content = <AuthView />;
      break;
    case 2:
      content = <RedeemableProduct code={code} />;
      break;
    case 3:
      content = (
        <RedeemConfirmation code={code} selectedProduct={selectedProduct} />
      );
      break;
    case 4:
      content = (
        <RedeemedSuccessful
          remainingRedeemableAmount={remainingRedeemableAmount}
        />
      );
      break;
  }

  return (
    <div className={styles.mask}>
      <Space direction={"vertical"} align={"center"} className={styles.layout}>
        <Space align="center" className={styles.container}>
          <Layout.Content className={styles.content}>
            <Space
              direction={"vertical"}
              align={"center"}
              style={{ width: "600px" }}
              size={16}
            >
              <Logo />
              <Steps current={step} size="small">
                <Steps.Step />
                <Steps.Step />
                <Steps.Step />
                <Steps.Step />
                <Steps.Step />
              </Steps>

              {content}
            </Space>
          </Layout.Content>
        </Space>
        <Footer />
      </Space>
    </div>
  );
}

export default App;
