import React, { ComponentProps } from "react";
import { message, Typography } from "antd";
import ViewContainer from "component/ViewContainer";
import { increment, setEmail } from "features/redemptionSlice";
import { Login } from "assets/icons";
import { useAppDispatch } from "app/store";
import { useAuth } from "hooks";
import { setView, setLoginOptions } from "../authSlice";
import LoginForm from "../LoginForm";
import styles from "./LoginView.module.scss";

function LoginView() {
  const dispatch = useAppDispatch();
  const { checkPassword } = useAuth();

  const goToResetView = () => dispatch(setView("RESET"));

  const handleSubmit: ComponentProps<typeof LoginForm>["onFinish"] = ({
    email,
    password,
  }) => {
    checkPassword(email, password)
      .then(() => {
        dispatch(setLoginOptions({ username: email, password: password }));
        dispatch(setEmail(email));
        dispatch(increment());
      })
      .catch((error: Error) => message.error(error.message));
  };

  return (
    <ViewContainer
      icon={<Login />}
      title={"Sign in"}
      subtitle={"If you already have Pimsleur account, please sign in"}
    >
      <LoginForm
        onBack={() => dispatch(setView("SIGNUP"))}
        onFinish={handleSubmit}
        submitText={"Sign in"}
      >
        <Typography.Paragraph
          type="secondary"
          // @ts-ignore
          onClick={goToResetView}
          className={styles.link}
          style={{ textAlign: "center" }}
        >
          Don’t remember your password?
        </Typography.Paragraph>
      </LoginForm>
    </ViewContainer>
  );
}

export default LoginView;
