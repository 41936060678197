import moment, { now } from "moment";
import { omit } from "lodash";
import instance from "../utils/instance";
import {
  RedeemableProductModel,
  VoucherRedeemRequest,
  RedeemResponse,
  VoucherRedeemableInfoResponse,
} from "../types/redemption";

export async function fetchRedeemableProducts({
  voucherCode,
  accessToken,
}: {
  voucherCode: string;
  accessToken?: string;
}) {
  const url = `/vouchers/${voucherCode}/redeemable-products`;
  return instance.get<RedeemableProductModel[]>(url, {
    headers: { Authorization: "Bearer " + accessToken },
  });
}

export async function redeem(data: VoucherRedeemRequest) {
  const url = `/vouchers/${data.voucherCode}/redemptions`;
  return instance.post<RedeemResponse>(url, omit(data, "accessToken"), {
    headers: { Authorization: "Bearer " + data.accessToken },
  });
}

export async function isRedeemable(voucherCode: string) {
  const url = `/vouchers/${voucherCode}/redeemable-info`;
  const response = await instance.get<VoucherRedeemableInfoResponse>(url);
  const { remainingRedeemableAmount, validPeriod, hasRevoked } = response;

  if (hasRevoked) {
    throw new Error(`Voucher has been revoked.`);
  } else if (remainingRedeemableAmount === 0) {
    throw new Error(`The code ${voucherCode} has been redeemed.`);
  } else if (validPeriod.startDate > now()) {
    const startDateStr = moment(validPeriod.startDate).format("YYYY-MM-DD");
    throw new Error(
      `The code ${voucherCode} is not available before ${startDateStr}.`
    );
  } else if (validPeriod.expirationDate < now()) {
    throw new Error(`The code ${voucherCode} has expired.`);
  } else {
    return response;
  }
}
