import React from "react";
import { Typography } from "antd";
import styles from "./ViewContainer.module.scss";

const { Text, Title } = Typography;

interface ViewContainerProps {
  icon?: React.ReactNode;
  title: string;
  subtitle?: string;
}

function ViewContainer({
  icon,
  title,
  subtitle,
  children,
}: React.PropsWithChildren<ViewContainerProps>) {
  return (
    <div className={styles.container}>
      {icon}
      <Title level={4}>{title}</Title>
      <Text type="secondary" style={{ textAlign: "center" }}>
        {subtitle}
      </Text>
      <div className={styles.content}>{children}</div>
    </div>
  );
}

export default ViewContainer;
